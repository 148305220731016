@font-face {
  font-family: "Avapore";
  src: local("Avapore"), local("Avapore"),
    url("./assets/Avapore.ttf") format("truetype");
  /* font-weight: bold; */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModellicaMedium";
  src: local("ModellicaMedium"), local("Modellica-Medium"),
    url("./assets/BWModelica/BWModelica/BwModelica-Medium.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raitor";
  src: local("Raitor"), local("Raitor"),
    url("./assets/raitor-fonts/Raitor-Regular.ttf") format("truetype");
  /* font-weight: normal; */
  font-style: normal;
  font-display: swap;
}

p,
input,
::placeholder,
p span {
  font-family: "Avapore", sans-serif !important;
}

span,
button a,
span a {
  font-family: "ModellicaMedium", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
a,
h1 span {
  font-family: "Raitor", sans-serif;
  font-weight: 300 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  position: relative;
  background-color: #000;
  overflow: hidden;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
  -webkit-text-size-adjust: 100%;
  background: #1d1d1d;
}
* {
  font-variant-numeric: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #2f2f2f;
}
::-webkit-scrollbar-thumb:active {
  background: #2f2f2f;
}
::-webkit-scrollbar-track {
  background: #100f0f;
}
::-webkit-scrollbar-track:hover {
  background: #100f0f;
}
::-webkit-scrollbar-track:active {
  background: #100f0f;
}
::-webkit-scrollbar-corner {
  background: #100f0f;
}
.ant-select-dropdown-hidden:first-child,
.ant-select-dropdown:first-child {
  max-height: 300px !important;
  /* background-color: #fbba00012; */
  overflow: auto !important;
}
/* .ant-select-item-option-content,
.ant-select-item-option,
.ant-select-item .ant-select-item-option .ant-select-item-option-active {
  background-color: yellow;
} */
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: transparent !important;
}
.ant-form-item-explain-error {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

[data-splitting] {
  overflow: hidden;
  visibility: hidden;
  will-change: transform;
}

.scroll span {
  color: #fff;
  font-size: 18px;
}
.scroll {
  position: absolute;
  right: 20px;
  top: 85vh;
  animation: pop 5s infinite ease 2s;
  transform: translate(-50%, -50%);
}

@keyframes pop {
  0% {
    transform: translateY(-24px);
  }
  5% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-24px);
  }
  15%,
  100% {
    transform: translateY(0);
  }
}
.ant-popover-inner {
  background: #141414e0 !important;
  max-width: 300px !important;
  min-height: 350px;
  border: 1px solid #fdb91399;
  color: #fff !important;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-spin-dot-item {
  background: #fdb913 !important;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999 !important;
  background-color: #999999 !important;
  color: #fff;
}

input:disabled,
input[disabled] {
  border: 1px solid #99999969 !important;
  background-color: #99999969 !important;
  color: transparent !important;
}
input:disabled::placeholder,
input[disabled]::placeholder {
  color: transparent !important;
}
